import type { IDropedItems } from '../../store';

import { FC, MutableRefObject, useEffect, useRef } from 'react';

import { DropTargetMonitor, useDrop } from 'react-dnd';
import { v4 } from 'uuid';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IPosition, IsConfigDrawerOpen, useGetRecipient } from 'views';
import { GifLoader } from '@storybook';
import { IsViewOnlyConfigState } from 'states';
import { TAB_SOURCE } from 'constant';

import {
	DragTypes,
	DropItems,
	DropItemsState,
	EditorNodesLoadedState,
	SelectedConfigNode,
	useCoordinates,
} from '..';

type Props = {
	_id: string;
	height: number;
	width: number;
	idx: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	doc: any;
	path: string;
	dropContainerDimension: MutableRefObject<{ width: number; height: number }>;
	showZindex: boolean;
};

export const ConfigDocumentDrop: FC<Props> = ({
	_id,
	height,
	width,
	idx,
	doc,
	path,
	dropContainerDimension,
	showZindex
}) => {
	const documentId = doc._id;
	const containerRef = useRef<HTMLDivElement>(null);
	const acceptableTypes = [DragTypes.DroppedItem, DragTypes.SideBarItem];
	const setIsDrawerOpen = useSetRecoilState(IsConfigDrawerOpen);
	const setDropItems = useSetRecoilState(DropItemsState);
	const [activeNode, setActiveNode] = useRecoilState(SelectedConfigNode);
	const { activeRecipientId } = useGetRecipient();
	const { getNewXYCoordinates } = useCoordinates();
	const isEditorNodesLoaded = useRecoilValue(EditorNodesLoadedState);
	const readOnly = useRecoilValue(IsViewOnlyConfigState);
	const [, dropRef] = useDrop({
		accept: acceptableTypes,
		// !TODO need to ask the type for drop function because drop takes parameter of item as unknown
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		drop(item: IDropedItems | any, monitor: DropTargetMonitor) {
			const dropContainer = document.getElementById(_id);
			const dropPage = idx + 1;
			if (dropContainer) {
				const container = dropContainer.getBoundingClientRect();
				const itemWidth = item?.offsetWidth ?? 50;
				const itemHeight = item?.offsetHeight ?? 50;
				const { x: clientX, y: clientY } = monitor.getSourceClientOffset() || {
					x: 0,
					y: 0,
				};
				const { width: containerWidth, height: containerHeight } = container;
				dropContainerDimension.current = {
					width: containerWidth,
					height: containerHeight,
				};
				const [positionFromLeftPercent, positionFromTopPercent] =
					getNewXYCoordinates(
						container,
						itemWidth,
						itemHeight,
						clientX,
						clientY,
						{ offsetX: 0, offsetY: 0 }
					);
				if (positionFromLeftPercent && positionFromTopPercent) {
					if (monitor.getItemType() === DragTypes.DroppedItem) {
						const { metadata } = item;
						const { height: elementHeight, width: elementWidth } = metadata;
						const nodeWidth = parseInt(`${elementWidth}`.replace('px', ''));
						const nodeHeight = parseInt(`${elementHeight}`.replace('px', ''));

						// Calculate new positions based on container size
						const xLeft = positionFromLeftPercent;
						const yTop = positionFromTopPercent;
						const xRight =
							(xLeft * containerWidth + nodeWidth) / containerWidth;
						const yBottom =
							(yTop * containerHeight + nodeHeight) / containerHeight;
						const oldItem = {
							...item,
							pageNumber: dropPage,
							position: {
								xLeft,
								yTop,
								xRight,
								yBottom,
							} as IPosition,
							documentId,
							isNewField: true,
						} as IDropedItems;
						if (item.fieldType === 'text') {
							oldItem.pageWidth = width;
							oldItem.containerWidth = container.width;
						}
						setDropItems((items) => {
							const filteredItems = items.filter(({ id }) => id !== item.id);
							filteredItems.push(oldItem);
							return filteredItems;
						});

						setActiveNode({
							fieldType: oldItem.fieldType,
							index: idx,
							id: oldItem.id,
							isEditable: oldItem.isEditable,
							recipient: oldItem.recipient,							
							source: oldItem.source
						});
					} else if (monitor.getItemType() === DragTypes.SideBarItem) {
						const newItem = {
							...item,
							pageNumber: dropPage,
							recipient: activeRecipientId,
							id: v4(),
							position: {
								xLeft: positionFromLeftPercent,
								yTop: positionFromTopPercent,
							},
							metadata: {},
							documentId,
							isNewField: true,
						} as IDropedItems;
						if (item.fieldType === 'text') {
							newItem.pageWidth = width;
							newItem.containerWidth = container.width;
						}
						if (item.fieldType === 'radiogroup') {
							newItem.name = newItem.source === TAB_SOURCE.QUESTIONAIRE ? newItem.key : v4();
						}
						setDropItems((items: IDropedItems[]) => [...items, newItem]);
						setActiveNode({
							fieldType: newItem.fieldType,
							index: idx,
							id: newItem.id,
							isEditable: newItem.isEditable,
							recipient: activeRecipientId,
							source: newItem?.source ?? ''
						});
					}
				}
				const LeftSidebarWidth: number = document.getElementById("left-sidebar-panel")?.offsetWidth || 0;
				if (clientX > LeftSidebarWidth) {
					setIsDrawerOpen(true);
				}
			}
		},
	});

	useEffect(() => {
		const element = containerRef.current?.querySelector(`.drop-item--active`);
		if (element) {
			(element as HTMLElement).focus({ preventScroll: true });
		}
	}, [activeNode]);

	return (
		<div
			style={{
				width: '100%',
				display: 'grid',
			}}
			ref={containerRef}
		>
			<div
				className="config-document__document-wrapper"
				id={_id}
				style={{
					minHeight: height,
					minWidth: width,
					width: '100%',
					height: '100%',
					margin: 'auto',
					pointerEvents: readOnly ? 'none' : 'auto',
				}}
				ref={dropRef}
			>
				<img
					draggable={false}
					className="config-document__document"
					id="image-generated"
					src={path}
					alt="pdfImage"
					loading="lazy"
					style={{
						width: '100%',
						height: '100%',
					}}
					key={_id}
				/>
				{isEditorNodesLoaded ? (
					<DropItems
						pageId={_id}
						pageWidth={width}
						pageHeight={height}
						pageNumber={idx + 1}
						docId={doc._id}
						showZIndex={showZindex}
					/>
				) : (
					<div
						className='config-document__nodes-loading'
					>
						<GifLoader dimension={60} />
					</div>
				)}
			</div>
			<div
				className="config-document__doc-description"
				style={{
					minWidth: width,
					width: '100%',
					margin: 'auto',
				}}
			>
				<span className="config-document__doc-title">{doc.name ?? ''}</span>
				<span className="config-document__doc-page-number">{`Page ${
					idx + 1
				} of ${doc?.images?.length}`}</span>
			</div>
		</div>
	);
};
