import type { IFocusEvent, IInputChange, ITextAreaChange } from 'types';

import {
	CSSProperties,
	FC,
	FocusEvent,
	MouseEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import { Confirmation, Tooltip } from '@storybook';
import {
	CreateSignState,
	DROP_ITEM_SOURCE,
	DeviceType,
	IsCreateSignModalOpen,
	ScaledDiv,
	docStatus,
	useDeviceType,
	useSetCreateSign,
} from 'views';
import { useOutsideClick } from 'hooks';
import { isValidEmail, isSafari } from 'utils';
import { APPEARANCE_CONFIG_KEYS, CHECKBOX_TO_TEXT_SIZE_MAP } from 'views/config-items/components/checkbox-configuration-component';
import { RADIO_APPEARANCE_CONFIG_KEYS } from 'views/config-items/components/radiogroup-configuration-component/store/constants';

import {
	CompiledSigningNodesState,
	ITab,
	SignDocSelector,
	currentClickedNodeState,
	selectedNodeState,
	useNodeIterator,
} from '../store';

import { TextNode } from './text-node';
import { TextInputNode } from './text-input-node';
import { DateNode } from './date-node';
import { EditOptions } from './edit-options';

interface ISigningNode {
	node: ITab;
	index: number;
	pageId: string;
	pageWidth: number;
	pageHeight: number;
}

export const SigningNodes: FC<ISigningNode> = ({
	node,
	index,
	pageId,
	pageWidth,
	pageHeight,
}) => {
	const [showOption, setShowOption] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);
	const [isDocSigned, setIsDocSigned] = useState(false);
	const [dateFormate, setDateFormate] = useState('');
	const [isConfirmationModal, setIsConfirmationModal] =
		useState<boolean>(false);
	const setSigningNodes = useSetRecoilState(CompiledSigningNodesState);
	const { userBioMetrics, recipient, status, purpose } = useRecoilValue(SignDocSelector);
	const setModalState = useSetRecoilState(IsCreateSignModalOpen);
	const [selectedNode, setSelectedNode] = useRecoilState(selectedNodeState);
	const setConfigNode = useSetRecoilState(CompiledSigningNodesState);
	const setCreateSignModalOpen = useSetRecoilState(IsCreateSignModalOpen);
	const setCreateSign = useSetRecoilState(CreateSignState);
	const setCurrentClickedSignNode = useSetRecoilState(currentClickedNodeState);

	const { nodeIterator } = useNodeIterator();
	const { handleDeleteSignature } = useSetCreateSign();
	const { deviceType } = useDeviceType();

	const {
		label,
		metadata,
		type,
		value,
		_id: nodeId,
		name,
		position,
		source
	} = node;
	const nodeType = useMemo(() =>{
		const validations = node.metadata.validations;
		if(validations && validations.type == 'date'){
			setDateFormate(validations.value);
			return validations.type;
		}
		return type
	}, [type])
	const { colorCode } = recipient;

	const biometrics = useMemo(() => {
		const { initial, signature } = userBioMetrics;
		const { document: initialDocument, type: initialType } = initial ?? {};
		const {
			document: signDocument,
			type: signType,
			_id: signId,
		} = signature ?? {};
		const { path: initialPath } = initialDocument ?? {};
		const { path: signPath } = signDocument ?? {};

		return { initialPath, initialType, signPath, signType, signId };
	}, [userBioMetrics]);

	const width = metadata.width ? metadata.width : '100%';
	const height = metadata.height ? metadata.height : '100%';

	const setConfigNodeValue = useCallback(
		(value: boolean | string) => {
			setConfigNode((prev) => {
				const prevState = JSON.parse(JSON.stringify(prev));
				const foundIndex = prevState.findIndex(
					(tab: ITab) => tab._id === nodeId
				);
				const foundTab = prevState[foundIndex];
				if(node.type === 'radiogroup' || node.questionType === 'radiogroup') { 
					prevState.forEach((tab: ITab) => { 
						if (tab.name === node.name) {
							tab.value = false;
						}
					})
				}
				prevState.splice(foundIndex, 1, { ...foundTab, value: value });
				return prevState;
			});
		},
		[nodeId, setConfigNode]
	);

	const handleClickInitial = useCallback(() => {
		if (node.recipientStatus === "completed") {
			return
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const biometricDoc = (userBioMetrics as any)?.[nodeType];
		setCurrentClickedSignNode({ nodeId });
		if (!biometricDoc) {
			setModalState(true);
			return;
		}
		setSigningNodes((prev) => {
			const prevState = JSON.parse(JSON.stringify(prev));
			const foundNode = prevState[index];
			if (foundNode && foundNode.isShow) {
				setShowOption((prev) => !prev);
			} else {
				foundNode.isShow = !foundNode?.isShow;
			}
			return prevState;
		});
		if (!node.isShow) {
			setTimeout(() => {
				nodeIterator(true, index);
			}, 200);
		}
		setConfigNodeValue(biometricDoc._id);
	}, [node, userBioMetrics, nodeType, setCurrentClickedSignNode, nodeId, setSigningNodes, setConfigNodeValue, setModalState, index, nodeIterator]);

	const handleNodeModal = useCallback(() => {
		setIsConfirmationModal(true);
	}, []);

	useOutsideClick(ref, () => {
		setShowOption(false);
	});

	const handleSelectNode = useCallback(
		(
			event:
				| MouseEvent<HTMLDivElement>
				| FocusEvent<HTMLInputElement>
				| MouseEvent<HTMLAnchorElement>
		) => {
			event.stopPropagation();
			if (!isDocSigned) {
				if (node?.metadata?.readOnly === false) {
					setSelectedNode({ index, node });
				}
				if (nodeType === 'signature' || nodeType === 'initial') {
					handleClickInitial();
				} else if (nodeType === 'hyperlink') {
					setConfigNodeValue(true);
				}
			}
		},
		[
			handleClickInitial,
			index,
			isDocSigned,
			node,
			nodeType,
			setConfigNodeValue,
			setSelectedNode,
		]
	);

	const handleShowModal = useCallback(
		(isOpen: boolean, value: boolean) => {
			setIsConfirmationModal(isOpen);
			if (value) {
				window.open(`${metadata?.url as string}`, '_blank', 'noreferrer');
			}
		},
		[metadata?.url]
	);

	const handleChangeText = useCallback(
		(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			event: any,
			key: 'textarea' | 'checkbox' | "input" | "date" | 'radiogroup'
		) => {
			let value: boolean | string = '';
			if (key === "date" || key === "input") {
				value = event
			}
			if (key === 'textarea') {
				value = event.target.value;
				event.target.style.height = 'auto';
				event.target.style.height = event.target.scrollHeight + 5 + 'px';
			}
			if (key === 'checkbox' || key === 'radiogroup') {
				value = event.target.checked;
			}			
			setConfigNodeValue(value);
			setSelectedNode({ index, node });
		},
		[index, node, setConfigNodeValue, setSelectedNode]
	);

	const readOnlyClass =
		metadata.readOnly === true || isDocSigned ? 'signing-node__read-only' : '';

	const isSelectedClass =
		selectedNode.index === index ? 'signing-node__selected-node' : '';

	const isCheckboxSelectedClass =
		selectedNode.index === index ? 'signing-node__selected-checkbox' : '';

	const initialImageClass = classNames(
		'signing-node__initial-img signing-node'
	);

	const signatureImageClass = classNames(
		'signing-node__signature-img signing-node'
	);

	// Construct the class name for Safari
	const safariClass = classNames({
		'signing-node__checkbox-safari': isSafari(), // Adds 'signing-node__checkbox-safari' if the browser is Safari
	});

	const conditionalWidth = classNames(
		{
			'signing-node__text--address': /address/gi.test(node.name),
		},
		{
			'signing-node__text--name': /name/gi.test(node.name),
		},
		{
			'signing-node__text--email': isValidEmail(node.name),
		}
	);

	const nodeStyle: CSSProperties = {
		backgroundColor: colorCode || '#eae2be',
	};

	const renderTextTypes = useCallback(() => {
		// Extracts fontSize and height from the metadata object
		const { fontSize, height } = metadata;

		// Converts fontSize to a number for comparison
		const fontSizeNumber = parseInt(`${fontSize?.replace?.('px', '')}`);

		// Determines whether to show a TextArea based on height and fontSize
		const showTextArea = parseInt(height) > fontSizeNumber * 2;

		// Checks if the text is in read-only mode or the document is signed
		if (metadata.readOnly === true || isDocSigned) {
			// Returns a ScaledDiv component for read-only mode or signed documents
			return (
				<ScaledDiv
					className={`signing-node__text signing-node ${readOnlyClass} ${conditionalWidth}`}
					onClick={handleSelectNode}
					styles={{
						height,
						width,
						padding: 2,
					}}
					id={`${nodeId}-textarea`}
					nodeType={node.type}
					width={width}
					height={height}
				>
					{value || metadata.editText || ' '}
				</ScaledDiv>
			);
		} else if (showTextArea) {
			// Returns a TextNode component for nodes requiring TextArea
			return (
				<TextNode
					handleChangeText={(e: ITextAreaChange & IInputChange & IFocusEvent) =>
						handleChangeText(e, 'textarea')
					}
					handleSelectedNode={handleSelectNode}
					isSelectedClass={isSelectedClass}
					name={name}
					metadata={metadata}
					label={label}
					node={node}
					nodeId={nodeId}
					value={value as string}
				/>
			);
		} else {	
			// Returns a TextInputNode component for regular text input
			return (
				<TextInputNode
					node={node}
					type="email"
					handleChangeText={(e: ITextAreaChange | string | IFocusEvent) =>
						handleChangeText(e, 'input')
					}
					handleSelectedNode={handleSelectNode}
					isSelectedClass={isSelectedClass}
					name={name}
					metadata={metadata}
					label={label}
					nodeId={nodeId}
					isDisabled={status === undefined}
					value={value as string}
					styles={{ height: metadata.height, alignItems: 'center' }}
				/>
			);
		}
	}, [
		conditionalWidth,
		handleChangeText,
		handleSelectNode,
		isDocSigned,
		isSelectedClass,
		label,
		metadata,
		name,
		node,
		nodeId,
		readOnlyClass,
		status,
		value,
		width,
	]);

	const reducedFontsizeInLabel = useMemo(() => {
		const heightNumber = parseInt(`${metadata.height}`.replace("px",""))

		if (heightNumber <= 25 ) {
			return `${(heightNumber/2.5) -2}px`
		}
		return "8px"
	},[metadata])


	const getCheckboxValue = useCallback((value: string) => {
		const checkoboxValue = value.toLowerCase();
		return checkoboxValue === "true" || checkoboxValue === metadata.checkboxValue?.toLowerCase();
	},[])

	const getRadiogroupValue = useCallback((value: string) => {
		const radiogroup = value.toLowerCase();
		return radiogroup === "true" || radiogroup === metadata.radiogroupValue?.toLowerCase();
	},[])

	const hideCheckbox = useMemo(() => {
        return metadata.checkboxAppearance === APPEARANCE_CONFIG_KEYS.onlyOptionVaue;
	},[metadata])

	const hideRadiogroup = useMemo(() => {
		return (
				metadata.radiogroupAppearance === RADIO_APPEARANCE_CONFIG_KEYS.onlyRadiogroup && node.value !== 'true' && node.source === 'questionaire' ||
				metadata.radiogroupAppearance === RADIO_APPEARANCE_CONFIG_KEYS.radiogroupWithValue && node.value !== 'true' && node.source === 'questionaire' || 
				metadata.radiogroupAppearance === APPEARANCE_CONFIG_KEYS.onlyOptionVaue)
	},[node, metadata]);

	const renderCheckboxValue = useMemo(() =>{
		const fontSize = metadata.fontSize;
		const noeCheckbox = metadata.checkboxAppearance === APPEARANCE_CONFIG_KEYS.onlyOptionVaue;
		return <p 
			style={{
				fontSize: fontSize ? CHECKBOX_TO_TEXT_SIZE_MAP[fontSize] : '10px',
				lineHeight: fontSize,
				paddingRight: '4px',
				paddingLeft: '4px',
				marginLeft: noeCheckbox ? 0 : metadata.width
			}}>
				{metadata.checkboxValue}
			</p>

	},[metadata]);

	const renderRadioValue = useMemo(() =>{
		const fontSize = metadata.fontSize;
		const noeCheckbox = metadata.radiogroupAppearance === RADIO_APPEARANCE_CONFIG_KEYS.onlyOptionVaue;
		
		return <p 
			style={{
				fontSize: fontSize ? CHECKBOX_TO_TEXT_SIZE_MAP[fontSize] : '10px',
				lineHeight: fontSize,
				paddingRight: '4px',
				paddingLeft: purpose === 'signAgreement' ? '12px' : '4px',
				marginLeft: noeCheckbox ? 0 : metadata.width
			}}>
				{metadata.radiogroupValue}
			</p>
	},[metadata, source]);

	const showCheckboxValue = useMemo(() =>{
		return (node.source === DROP_ITEM_SOURCE.questionaire && 
			node.type === "checkbox" && 
				(metadata.checkboxAppearance === APPEARANCE_CONFIG_KEYS.checkboxWithValue || 
					metadata.checkboxAppearance === APPEARANCE_CONFIG_KEYS.onlyOptionVaue)
			)
	},[node, metadata]);

	const showRadioValue = useMemo(() =>{
		return (
			node.type === "radiogroup" && 
				(metadata.radiogroupAppearance === RADIO_APPEARANCE_CONFIG_KEYS.radiogroupWithValue && (node.value === 'true' || node.source !== 'questionaire') || 
					metadata.radiogroupAppearance === APPEARANCE_CONFIG_KEYS.onlyOptionVaue && node.value === 'true')
			)
	},[node, metadata]);

	const renderNodes = useMemo(() => {
		switch (nodeType) {
			case 'checkbox':
				return (
					<>
						<div
							className={`signing-node__checkbox ${readOnlyClass} ${safariClass}`} // Dynamic classes for styling
							onClick={handleSelectNode} // Handles click event for selecting a node
							id={`${nodeId}-${nodeId}`} // Unique ID using nodeId
						>
							{/* Conditionally renders the checkbox input if hideCheckbox is false */}
							{!hideCheckbox && (
								<input
									onChange={(
										e: ITextAreaChange & IInputChange & IFocusEvent // Handles multiple event types
									) => handleChangeText(e, 'checkbox')} // Custom event handler for changes
									className={isCheckboxSelectedClass} // Dynamic class for selected state
									type="checkbox" // Specifies checkbox input type
									disabled={metadata.readOnly === true || isDocSigned} // Disables if readOnly or document is signed
									checked={getCheckboxValue(`${value}`)} // Controlled checked value from props or state
									style={{
										width: metadata.width, // Custom width based on metadata
										height: metadata.height, // Custom height based on metadata
										position: 'absolute', // Positioning the checkbox absolutely inside the container
									}}
								/>
							)}
							{/* Conditionally renders the checkbox value display */}
							{showCheckboxValue && renderCheckboxValue}
						</div>
						{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
							<Tooltip
								id={`${nodeId}-${nodeId}`}
								variant="info"
								content={`${
									node.metadata.required === false
										? 'Optional field'
										: 'Required field'
								} ${label}`}
								className="pdf-signer-container__tooltip"
							/>
						)}
					</>
				);
			case 'radiogroup':
				return (
					<>
						<div
							className={`signing-node__radiogroup ${readOnlyClass} ${safariClass}`} // Dynamic classes for styling
							onClick={handleSelectNode} // Handles click event for selecting a node
							id={`${nodeId}-${nodeId}`} // Unique ID using nodeId
						>
							{/* Conditionally renders the checkbox input if hideCheckbox is false */}
							{!hideRadiogroup && (
								<input
									onChange={(
										e: ITextAreaChange & IInputChange & IFocusEvent // Handles multiple event types
									) => handleChangeText(e, 'radiogroup')} // Custom event handler for changes
									className={isCheckboxSelectedClass} // Dynamic class for selected state
									type="radio" // Specifies checkbox input type
									name={name}
									disabled={metadata.readOnly === true || isDocSigned} // Disables if readOnly or document is signed
									checked={getRadiogroupValue(`${value}`)} // Controlled checked value from props or state
									style={{
										width: metadata.width, // Custom width based on metadata
										height: metadata.height, // Custom height based on metadata
										position: 'absolute', // Positioning the checkbox absolutely inside the container
									}}
								/>
							)}
							{/* Conditionally renders the checkbox value display */}
							{showRadioValue && renderRadioValue}
						</div>
						{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
							<Tooltip
								id={`${nodeId}-${nodeId}`}
								variant="info"
								content={`${
									node.metadata.required === false
										? 'Optional field'
										: 'Required field'
								} ${label}`}
								className="pdf-signer-container__tooltip"
							/>
						)}
					</>
				);
			case 'date':
				return metadata.readOnly === true ? (
					<div
						className="signing-node__date signing-node signing-node__read-only"
						onClick={handleSelectNode}
						style={{ height, alignItems: 'center' }}
					>
						{value || ''}
					</div>
				) : (
					<DateNode
						isSelectedClass={isSelectedClass}
						handleChangeText={(
							e: string
						) => {
							handleChangeText(e, 'date');
						}}
						handleSelectedNode={handleSelectNode}
						node={node}
						name={name}
						metadata={metadata}						
						pageHeight={pageHeight}
						pageWidth={pageWidth}
						label={label}
						nodeId={nodeId}
						isDisabled={status === undefined}
						value={value as string}
						styles={{
							fontFamily: metadata.fontFamily,
							fontSize: metadata.fontSize,
							alignItems: 'center',
						}}
						formate={dateFormate}
					/>
				);
			case 'email':
				return metadata.readOnly === true ? (
					<div
						className={`signing-node__email signing-node signing-node__read-only ${conditionalWidth}`}
						onClick={handleSelectNode}
						style={{ height, alignItems: 'center' }}
					>
						{value || recipient.email}
					</div>
				) : (
					<TextInputNode
						node={node}
						type="email"
						handleChangeText={(e: ITextAreaChange | string | IFocusEvent) =>
							handleChangeText(e, 'input')
						}
						handleSelectedNode={handleSelectNode}
						isSelectedClass={isSelectedClass}
						name={name}
						metadata={metadata}
						label={label}
						nodeId={nodeId}
						value={value as string}
						styles={{ height: metadata.height, alignItems: 'center' }}
					/>
				);
			case 'initial':
				return (
					<div onClick={handleSelectNode}>
						{!node.isShow ? (
							<>
								<ScaledDiv
									id={`${nodeId}-${nodeId}`}
									className={`signing-node__initial signing-node ${isSelectedClass}`}
									height={height}
									width={width}
									nodeType={node.type}
									styles={{ ...nodeStyle, fontSize: reducedFontsizeInLabel }}
								>
									{label} <i className="ri-download-line" />
									{/* {node.metadata.required === false &&
										!node.value &&
										node.metadata.readOnly !== true && (
											<div
												className={`pdf-signer-container__optional-flag-signature ${isSelectedClass}__optional-flag`}
											>
												Optional
											</div>
										)} */}
								</ScaledDiv>
								{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
									<Tooltip
										id={`${nodeId}-${nodeId}`}
										variant="info"
										content={`${
											node.metadata.required === false
												? 'Optional field'
												: 'Required field'
										} ${label}`}
										className="pdf-signer-container__tooltip"
									/>
								)}
							</>
						) : (
							<ScaledDiv
								id={`${nodeId}-${nodeId}`}
								height={height}
								width={width}
								nodeType={node.type}
							>
								<img
									style={{
										width,
										height,
										objectFit: 'contain',
										objectPosition: 'left top',
									}}
									/**
									 * this is added if the recipient already completed its signing then the
									 * sign and initial urls  be directly be rendered
									 * */
									src={
										(node.recipientStatus === 'completed'
											? node.value
											: biometrics.initialPath ?? '') as string
									}
									alt=""
									className={initialImageClass}
								/>
							</ScaledDiv>
						)}
					</div>
				);
			case 'hyperlink':
				return (
					<>
						<div onClick={handleSelectNode}>
							<button
								id={`${nodeId}-${nodeId}`}
								className={`signing-node__link signing-node ${isSelectedClass}`}
								onClick={handleNodeModal}
								style={{
									width,
									height,
									...nodeStyle,
									fontSize: reducedFontsizeInLabel,
								}}
							>
								{label} <i className="ri-share-box-line" />
							</button>
						</div>
						{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
							<Tooltip
								id={`${nodeId}-${nodeId}`}
								variant="info"
								content={`${
									node.metadata.required === false
										? 'Optional field'
										: 'Required field'
								} ${label}`}
								className="pdf-signer-container__tooltip"
							/>
						)}
					</>
				);
			case 'name':
				return metadata.readOnly === true ? (
					<div
						className={`signing-node__name signing-node signing-node__read-only ${conditionalWidth}`}
						onClick={handleSelectNode}
						style={{ height, alignItems: 'center' }}
					>
						{value || recipient.fullName}
					</div>
				) : (
					<TextInputNode
						handleChangeText={(e: ITextAreaChange | string | IFocusEvent) =>
							handleChangeText(e, 'input')
						}
						handleSelectedNode={handleSelectNode}
						isSelectedClass={isSelectedClass}
						type="text"
						name={name}
						metadata={metadata}
						node={node}
						label={label}
						nodeId={nodeId}
						value={value as string}
						styles={{ height: metadata.height, alignItems: 'center' }}
					/>
				);
			case 'signature':
				return (
					<div onClick={handleSelectNode}>
						{!node.isShow ? (
							<>
								<ScaledDiv
									className={`signing-node__signature signing-node ${isSelectedClass}`}
									id={`${nodeId}-${nodeId}`}
									height={height}
									width={width}
									nodeType={node.type}
									styles={{ ...nodeStyle, fontSize: reducedFontsizeInLabel }}
								>
									Sign<i className="ri-download-line"></i>
									{/* {node.metadata.required === false &&
										!node.value &&
										node.metadata.readOnly !== true && (
											<div
												className={`pdf-signer-container__optional-flag-signature ${isSelectedClass}__optional-flag`}
											>
												Optional
											</div>
										)} */}
								</ScaledDiv>

								{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
									<Tooltip
										id={`${nodeId}-${nodeId}`}
										variant="info"
										content={`${
											node.metadata.required === false
												? 'Optional field'
												: 'Required field'
										} ${label}`}
										className="pdf-signer-container__tooltip"
									/>
								)}
							</>
						) : (
							<ScaledDiv
								id={`${nodeId}-${nodeId}`}
								height={height}
								width={width}
								nodeType={node.type}
							>
								<img
									style={{
										width,
										height,
										objectFit: 'contain',
										objectPosition: 'left top',
									}}
									src={
										(node.recipientStatus === 'completed'
											? node.value
											: biometrics.signPath ?? '') as string
									}
									alt=""
									className={signatureImageClass}
								/>
							</ScaledDiv>
						)}
					</div>
				);
			case 'text':
				return <div>{renderTextTypes()}</div>;
			case 'title':
				return node.recipientStatus !== 'completed' ? (
					<TextInputNode
						node={node}
						type="text"
						handleChangeText={(e: ITextAreaChange | string | IFocusEvent) =>
							handleChangeText(e, 'input')
						}
						handleSelectedNode={handleSelectNode}
						isSelectedClass={isSelectedClass}
						name={name}
						metadata={metadata}
						label={label}
						nodeId={nodeId}
						value={value as string}
						styles={{ height: metadata.height, alignItems: 'center' }}
					/>
				) : (
					<div>{renderTextTypes()}</div>
				);
			default:
				return <div>{renderTextTypes()}</div>;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		nodeType,
		readOnlyClass,
		handleSelectNode,
		nodeId,
		isCheckboxSelectedClass,
		metadata,
		isDocSigned,
		value,
		deviceType,
		node,
		label,
		height,
		isSelectedClass,
		name,
		conditionalWidth,
		recipient.email,
		recipient.fullName,
		width,
		reducedFontsizeInLabel,
		biometrics.initialPath,
		biometrics?.signPath,
		initialImageClass,
		handleNodeModal,
		signatureImageClass,
		renderTextTypes,
		handleChangeText,
	]);

	useEffect(() => {
		setIsDocSigned(status === docStatus[1]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSignatureDelete = useCallback(() => {
		const { type } = node;
		handleDeleteSignature(type);
		setShowOption(false);
	}, [handleDeleteSignature, node]);

	const handleSignatureChange = useCallback(() => {
		if (node?.metadata?.readOnly === false) {
			setSelectedNode({ index, node });
		}
		setCreateSign((prev) => ({
			...prev,
			isEdit: true,
		}));
		setCreateSignModalOpen(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index, node]);

	useEffect(() => {
		const pageContainer = document.getElementById(pageId);
		const nodeContainer = document.getElementById(`${nodeId}-textarea`);
		if (pageContainer && nodeContainer) {
			const pageBoundingClientRect = pageContainer.getBoundingClientRect();
			const elementBoundingClientRect = nodeContainer.getBoundingClientRect();
			const diff =
				pageBoundingClientRect.right - elementBoundingClientRect.right;
			if (diff < 0) {
				nodeContainer.style.width = `${
					elementBoundingClientRect.width + diff
				}px`;
			}
		}
	});

	return (
		<div ref={ref}>
			<div
				id={nodeId}
				className="positionRelative"
			>
				<div>
					{renderNodes}
				</div>
			</div>
			<Confirmation
				handleModal={handleShowModal}
				visible={isConfirmationModal}
				description="You want to proceed on url"
				boldDescription={` ${(metadata?.url as string) ?? '#'}`}
				label="Proceed"
				title="Are you sure?"
			/>
			{showOption && (
				<EditOptions
					handleSignatureChange={handleSignatureChange}
					handleSignatureDelete={handleSignatureDelete}
					pageWidth={pageWidth}
					pageHeight={pageHeight}
					position={position}
					isDeleteOption={false}
				/>
			)}
		</div>
	);
};
